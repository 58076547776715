import React,{useLayoutEffect} from 'react';
import "../CSS/AboutUS.css";
import NavBar from '../Component/NavBar';
import WhoWeAre from "../Image/Who We Are 01.jpg";
import OurImpact from "../Image/Our Impact.jpg";
import MissionPromise from "../Image/Mission & Promise.jpg";
import hands from "../Image/paw.png";
import Footer from '../Component/Footer';

import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';

const AboutUs = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });
    return (
        <>

            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            < section className='AboutUsSection1' >
                <div className='AboutUs-banner'>
                    <NavBar />
                    <div className='AboutUs-banner-text'>
                        <div className='HB-Text'>
                            <Bounce left>
                                <h1>Pet Doctor</h1>
                                <p>Cats do speak, but only to those who know how to listen.</p>
                            </Bounce>
                        </div>
                    </div>
                </div>
            </section >
            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='AboutUsSection2'>
                <div className='AboutUsSection2-Heading'>
                    <Zoom>
                        <h2>About Us</h2>
                    </Zoom>
                </div>
                <div className='container'>
                    <div className='AboutUSWhoWeAre'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Zoom>
                                    <div className='AboutUs_info-con'>
                                        <div className='AboutUs_info-title'>
                                            <h2>Who <strong style={{ color: "#00a894" }}>We Are</strong></h2>
                                            <p>Dr shalaka pet clinic in Thane west , has many years of experience providing excellent service to pet owners and their loved ones! We are proud to have knowledge and expertise in the care of a variety of pets . We're with you on the journey of your pet's health and we strive to improve every animals quality of life, one visit at a time.</p>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>

                            <div className='col-md-6'>
                                <Slide top>
                                    <div className='AboutUsImage-con'>
                                        <div className='AboutUs-Image'>
                                            <img src={WhoWeAre} alt="Dog Treatmnet" />
                                            <div className='HandImage'>
                                                <img src={hands} alt="Cat Treatment" />

                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>

                    <hr></hr>

                    {/* //////////////////////// */}


                    <div className='AboutUSWhoWeAre'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Slide left>
                                    <div className='AboutUsImage-con'>
                                        <div className='AboutUs-Image'>
                                            <img src={OurImpact} alt="dog care center" />
                                            <div className='HandImage'>
                                                <img src={hands} alt="cat care center" />

                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>

                            <div className='col-md-6'>
                                <Zoom>
                                    <div className='AboutUs_info-con'>
                                        <div className='AboutUs_info-title'>
                                            <h2>Our <strong style={{ color: "#00a894" }}> Impact</strong></h2>
                                            <p>
                                                Our veterinary services and facilities are designed to assist in routine preventive care for young, healthy pets, early detection and treatment of disease as your pet ages, and complete medical and surgical, including spaying & neutering. We love caring for cats and dogs.
                                            </p>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                    </div>

                    <hr></hr>
                    {/* //////////////////// */}


                    <div className='AboutUSWhoWeAre'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Zoom>
                                    <div className='AboutUs_info-con'>
                                        <div className='AboutUs_info-title'>
                                            <h2>Mission<strong style={{ color: "#00a894" }}> & </strong> Promise</h2>
                                            <p>
                                                At Dr shalaka pet Clinic it is our goal to provide the very best veterinary care to your pets, our valued patients.
                                            </p>
                                        </div>
                                    </div>
                                </Zoom>
                            </div>
                            <div className='col-md-6'>
                                <Slide bottom>
                                    <div className='AboutUsImage-con'>
                                        <div className='AboutUs-Image'>
                                            <img src={MissionPromise} alt="cat food" />
                                            <div className='HandImage'>
                                                <img src={hands} alt="dog food" />
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////// */}

            <Footer />

        </>
    )
}

export default AboutUs