import React,{useLayoutEffect} from 'react';
import "../CSS/PetTips.css";
import NavBar from "../Component/NavBar.jsx";

import DogSwimming from "../Image/dog swimming.webp";

import HolidayFoodDangers from "../Image/Holiday Food Dangers.webp";

import PetTipdData from '../Component/PetTipsData';
import Footer from '../Component/Footer';

import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';




const PetTips = () => {

    
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });

    return (
        <>
            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            <section className='PetTipsSection1'>
                <div className='PetTips-banner'>
                    <NavBar />
                    <div className='PetTips-banner-text'>
                        <Bounce left>
                            <div className='HB-Text'>
                                <h1>Pet Doctor</h1>
                                <p>Dogs do speak, but only to those who know how to listen.</p>
                            </div>
                        </Bounce>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='PetTipsSection'>
                <div className='PetTipsSection-Title'>
                    <h2>Blog</h2>
                </div>
                <div className='PetSection2Firstrow'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-5'>
                                <div className='DogSwimming-info'>
                                    <Slide left>
                                        <div className='DogSwimming-title'>
                                            <h2>Is The Swimming Pool <strong style={{ color: "#00a894" }}>Safe For Your Pets?</strong></h2>
                                            <p>The pool is a great place for your family and pets to spend their summers. It’s the perfect solution to cooling down, soaking up some sun, and having lots of fun. However, like with small children, your dog can potentially be at risk of getting hurt if not properly supervised. Not all dogs are great swimmers and not all pools are considered dog friendly.</p>
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                            <div className='col-md-7'>
                                <div className='DogSwimming-image-con'>
                                    <Slide right>
                                        <div className='DogSwimming-image'>
                                            <img src={DogSwimming} alt="" />
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* /////////////////////////////// section 3 /////////////////////////////////// */}

            <section className='PetTipsSection3'>
                <div className='container'>
                    <div className='row'>
                        {
                            PetTipdData.map((ele) => {
                                const { PetDataTitile, PetDataDescription, Image } = ele
                                return (
                                    <>
                                        <div className='col-md-4'>
                                            <Zoom>
                                                <div className='blog-tips-card'>
                                                    <div className='blog-cardImage'>
                                                        <img src={Image} alt="" />
                                                    </div>
                                                    <div className='blog-card-data'>
                                                        <div className='blog-card-Title'>
                                                            <h2>{PetDataTitile}</h2>
                                                            <p>{PetDataDescription}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Zoom>
                                        </div>
                                    </>
                                )
                            })
                        }

                    </div>
                </div>
            </section>

            {/* ///////////////// */}

            <Footer />


        </>
    )
}

export default PetTips;