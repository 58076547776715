import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from './Pages/HomePage';
import Gallery from './Pages/Gallery';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import Facilities from './Pages/Facilities';
import PetTips from './Pages/PetTips';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/pet-grooming-clinic' element={<Gallery />} />
          <Route path='/pet-boarding-in-thane' element={<AboutUs />} />
          <Route path='/dog-boarding-in-thane' element={<ContactUs />} />
          <Route path='/pet-hospital-in-thane' element={<Facilities />} />
          <Route path='/pet-shop-in-thane' element={<PetTips />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
