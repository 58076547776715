import HolidayFoodDangers from "../Image/Holiday Food Dangers.webp";
import IndoorGameswithPets from "../Image/Indoor Games with Pets.webp";
import CoolPetTips from "../Image/Cool Pet Tips.webp";
import BoneBroth from "../Image/Bone Broth, Goat Milk.webp";
import NewHome from "../Image/New Home With a Cat.webp";
import CatAlwaysHungry from "../Image/CatAlwaysHungry.webp";
import TickRemoval from "../Image/Tick Removal.webp";
import TravelwithCats from "../Image/Travel with Cats.webp";
import TrainingYourDog from "../Image/Dog Training Treats.webp";


const GalleryData = [
    {
        id:1,
        gImage:HolidayFoodDangers,
    },
    {
        id:2,
        gImage:IndoorGameswithPets,
    },
    {
        id:3,
        gImage:CoolPetTips,
    },
    {
        id:4,
        gImage:NewHome,
    },
    {
        id:5,
        gImage:BoneBroth,
    },
    {
        id:6,
        gImage:CatAlwaysHungry,
    },
    {
        id:7,
        gImage:TickRemoval,
    },
    {
        id:8,
        gImage:TravelwithCats,
    },
    {
        id:9,
        gImage:TrainingYourDog,
    },
];

export default GalleryData;