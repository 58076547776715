import React, { useState,useLayoutEffect } from 'react';
import "../CSS/Gallery.css";
import NavBar from '../Component/NavBar';
import GalleryData from '../Component/GalleryData';
import Model from '../Component/Model';
import Zoom from 'react-reveal/Zoom';
import Footer from '../Component/Footer';
import Bounce from 'react-reveal/Bounce';

const Gallery = () => {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
      });

    const [clickImage, setClickImage] = useState(null)
    const [cureentIndex, setCureentIndex] = useState(null)

    const handelClick = (item, index) => {
        setCureentIndex(index);
        setClickImage(item.gImage);

    };

    const handelRotationRight = () => {
        const totallength = GalleryData.length;

        if (cureentIndex + 1 >= totallength) {
            setCureentIndex(0);
            const newurl = GalleryData[0].gImage;
            setClickImage(newurl)
            return;
        };
        const newIndex = cureentIndex + 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totallength = GalleryData.length;
        if (cureentIndex === 0) {
            setCureentIndex(totallength - 1);
            const newUrl = GalleryData[totallength - 1].gImage;
            setClickImage(newUrl);
        }
        const newIndex = cureentIndex - 1;
        const newUrl = GalleryData.filter((item) => {
            return GalleryData.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].gImage;
        setClickImage(newItem);
        setCureentIndex(newIndex);
    };



    return (
        <>

            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            < section className='GallerySection1' >
                <div className='Gallery-banner'>
                    <NavBar />
                    <div className='Gallery-banner-text'>
                        <div className='HB-Text'>
                            <Bounce left>
                                <h1>Pet Doctor</h1>
                                <p>Dogs do speak, but only to those who know how to listen.</p>
                            </Bounce>
                        </div>
                    </div>
                </div>
            </section >




            {/* /////////////////////////// Section 2 /////////////////////////////////////////// */}

            <section className='Image-Gallery'>
                <div className='Image-Gallery-title'>
                    <h4>Image gallery</h4>
                </div>
                <div className='container'>
                    <div className='row'>
                        {
                            GalleryData.map((item, index) => {
                                return (
                                    <>
                                        <div className='col-md-4'>
                                            <Zoom>
                                                <div className='G-Images' key={index}>
                                                    <img src={item.gImage} alt="pet shops in thane ghodbunder road" onClick={() => handelClick(item, index)} />
                                                </div>
                                            </Zoom>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {clickImage && (
                <Model
                    clickImage={clickImage}
                    handelRotationRight={handelRotationRight}
                    setClickImage={setClickImage}
                    handelRotationLeft={handelRotationLeft}
                />
            )}


            {/* //////////// */}

            <Footer />

        </>
    )
}

export default Gallery