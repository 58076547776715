import HolidayFoodDangers from "../Image/Holiday Food Dangers.webp";
import IndoorGameswithPets from "../Image/Indoor Games with Pets.webp";
import CoolPetTips from "../Image/Cool Pet Tips.webp";
import BoneBroth from "../Image/Bone Broth, Goat Milk.webp";
import NewHome from "../Image/New Home With a Cat.webp";
import CatAlwaysHungry from "../Image/CatAlwaysHungry.webp";
import TickRemoval from "../Image/Tick Removal.webp";
import TravelwithCats from "../Image/Travel with Cats.webp";
import TrainingYourDog from "../Image/Dog Training Treats.webp";






const PetTipdData = [
    {
        id:1,
        PetDataTitile:"Holiday Food Dangers for Dogs and Cats",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:HolidayFoodDangers,
    },
    {
        id:2,
        PetDataTitile:"Indoor Games with Pets",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:IndoorGameswithPets,
    },
    {
        id:3,
        PetDataTitile:"Cool Pet Tips for the Dog Days of Summer",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:CoolPetTips,
    },
    {
        id:4,
        PetDataTitile:"Bone Broth, Goat Milk, and Your Pet’s Nutrition",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:BoneBroth,
    },
    {
        id:5,
        PetDataTitile:"New Home With a Cat",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:NewHome,
    },
    {
        id:6,
        PetDataTitile:"Why Is My Cat Always Hungry?",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:CatAlwaysHungry,
    },
    {
        id:7,
        PetDataTitile:"Tick Removal and Prevention Tips",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:TickRemoval,
    },
    {
        id:8,
        PetDataTitile:"How to Travel with Cats",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:TravelwithCats,
    },
    {
        id:9,
        PetDataTitile:"Training Your Dog With Healthy Dog Training Treats",
        PetDataDescription:"Rule number one for pet pool safety is to never leave your pet unattended around a pool. This has the potential to be a very dangerous situation. If you are aware that your dog is not a great swimmer, then invest in a life jacket to improve buoyancy.",
        Image:TrainingYourDog,
    },
];

export default PetTipdData;