import React from 'react';
import "../CSS/NavBar.css";
import Zoom from 'react-reveal/Zoom';

////
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Link } from 'react-router-dom';

import Logo from "../Image/Logo2.png";
import Show from "../Image/section-separator-bottom.png";

const NavBar = () => {
    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark">
                <Container>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Link to='/'>Home</Link>
                            <Link to='/pet-hospital-in-thane'>facilities</Link>
                            <Link to='/pet-shop-in-thane'>Pet Tips</Link>
                        </Nav>
                        <Navbar.Brand>
                            <div className='mid-logo'>
                                <Zoom>
                                    <div className='LogoImage'>
                                        <Link to="/"> <img src={Logo} alt="pet shops in thane ghodbunder road" /></Link>
                                    </div>
                                </Zoom>
                            </div>
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <Link to='/pet-grooming-clinic'>Gallery</Link>
                            <Link to='/pet-boarding-in-thane'>About Us</Link>
                            <Link to='/dog-boarding-in-thane'>Contact Us</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
                <div className='SmallSLogo'>
                    <div className='mid-logo'>
                        <div className='LogoImage'>
                            <Link to="/"> <img src={Logo} alt="pet shops in hiranandani estate thane" /></Link>
                        </div>
                    </div>
                </div>
            </Navbar>
            <div className='showcon'>
                <img src={Show} alt="pet shop and clinic in thane" />
            </div>
        </>
    )
}

export default NavBar