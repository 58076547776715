import React, { useState, useLayoutEffect } from 'react';
import "../CSS/Facilities.css";
import NavBar from '../Component/NavBar';
//////
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
/////

import Facilitieslogo01 from "../Image/reslide_dog_2.png";
import Facilitieslogo02 from "../Image/reslide_dog_3.png";

import Footer from '../Component/Footer';

import Treatment from "../Image/Tratment.jpeg";
import VACCINATION from "../Image/VACCINATION.jpg";
import SURGERIES from "../Image/SURGERIES.jpg";
import DIAGNOSTICS from "../Image/DIAGNOSTICS.webp";
import PetShop from "../Image/Pet Shop.jpg";
import PETBOARDING from "../Image/PET BOARDING.jpg";

import PetGromming from "../Image/petgrooming.jpg";
import XRay from "../Image/X-Ray.jpg";
import pathology from "../Image/pathology.jpg";
import NailCutting from "../Image/Nail Cutting.jpg";
import HairCutting from "../Image/Hair Cutting.jpg";
import EarCleaning from "../Image/Ear-Cleaning.jpg";
import bathing from "../Image/Bathing.jpg"


import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';


const Facilities = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    ///////
    const [key, setKey] = useState('home');

    ///////
    return (
        <>
            {/* /////////////////////////////// section 1 /////////////////////////////////// */}
            < section className='FacilitiesSection1' >
                <div className='Facilities-banner'>
                    <NavBar />
                    <div className='Facilities-banner-text'>
                        <div className='HB-Text'>
                            <Bounce left>
                                <h1>Pet Doctor</h1>
                                <p>Dogs do speak, but only to those who know how to listen.</p>
                            </Bounce>
                        </div>
                    </div>
                </div>
            </section >

            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='FacilitiesSection2'>
                <div className='FacilitiesSection2-heading'>
                    <Slide left>
                        <span><img src={Facilitieslogo01} alt="cat" /></span>
                    </Slide>

                    <Zoom>
                        <h2>Our Specialty Services</h2>
                    </Zoom>

                    <Slide right>
                        <span><img src={Facilitieslogo02} alt="dog" /></span>
                    </Slide>
                </div>
                <div className='Container'>
                    <div className='Facilities-Section2-tabpanel'>

                        <Tabs
                            defaultActiveKey="Treatment"
                            id="justify-tab-example"
                            className="mb-3"
                            justify

                        >
                            <Tab eventKey="Treatment" title="Treatment">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={Treatment} alt="Pet Treatment" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Treatment</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>While we have expert eyes and hands, they can’t see and feel all that is wrong with your pet. Your pet’s internal health is only available to us through pet diagnostics, including laboratory tests and advanced imaging. We use our in-house equipment regularly to paint a fuller picture of your pet’s entire health so we can develop more informed and more effective treatment plans.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="Vaccination" title="Vaccination">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={VACCINATION} alt="Vaccination" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Vaccination </h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>Veterinary vaccinations can play a very important role in your pet’s life. They can be an integral part in keeping our loved ones healthy and free from contagious, life threatening diseases. There are many factors to consider when vaccinating your pets. All too often vaccines are considered routine, but they should be treated as any other medical or surgical procedure and given the same amount of thought and care. Here at Stevens Animal Hospital, our focus is on using vaccinations that are appropriate for your pets.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Surgeries" title="Surgeries">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={SURGERIES} alt="SURGERIES" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>SURGERIES</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>We perform spaying, neutering, Soft tissue and orthopedic surgeries with modern specialties.</p>
                                                                <p>Spaying your pet has many benefits. The procedure, which prevents female animals from becoming pregnant and reproducing, can help your dog or cat live a longer, healthier life. Spaying will not change your pet’s personality.</p>
                                                                <p>Neutering, which involves removing the testicles, is a surgical procedure and does need to be performed with the pet under anesthesia. We follow strict protocols and continually monitor your pet’s vital signs to help ensure his safety. Please see the descriptions under Anesthesia and Patient Monitoring for more information on what we do to keep your pet safe.</p>
                                                                <p>Probably the most common soft tissue surgery performed on pets is the removal of masses, or lumps. Most of these masses, once removed and tested, are found to be benign (nonharmful); however, occasionally they are more serious. Early removal and accurate diagnosis of a lump is necessary to improve the outcome in your pet if the mass is cancerous. If your dog suffers from frequent ear infections, surgical intervention can reduce their occurrence by improving airflow into the ear canal.</p>
                                                                <p>Pet orthopedic surgery for fractures is tailored for your pet’s specific injury. Some general repair methods include plate and screw fixation which are typically used on pelvis and long bones, pin fixation used for end bones and growth plate breaks and external fixators that are placed in the bones then connected.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Diagnostics" title="Diagnostics">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={DIAGNOSTICS} alt="DIAGNOSTICS" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>DIAGNOSTICS</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>We have a tie up with specialized veterinary laboratory for round the clock monitoring of blood values.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Pet Shop" title="Pet Shop">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={PetShop} alt="PET SHOP" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>PET SHOP</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>We are committed to helping you love and care for your Furry friend . Each accessories designed to support comfort and health for both of you and your  pets.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>
            {/* /////////////////// */}


            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='FacilitiesSection2'>
                <div className='Container'>
                    <div className='Facilities-Section2-tabpanel'>

                        <Tabs
                            defaultActiveKey="Gromming"
                            id="justify-tab-example"
                            className="mb-3"
                            justify

                        >
                            <Tab eventKey="Gromming" title="Gromming">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={PetGromming} alt="Gromming" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Gromming</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>Our grooming department offers the ultimate spa luxury for your furry “kid”. Your pet will enjoy a day of total relaxation and pampering. Their “treatment” includes a bath with the gentlest hypo allergenic shampoos, a manicure/pedicure, ear cleaning and anal gland expression. Our groomer will take extra care in giving your pet the hair style of your choice while ensuring complete comfort while doing so. </p>
                                                                <p>The main reasons for daily grooming include:</p>
                                                                <ul>
                                                                    <li>
                                                                        decreased chance of various health problems, such as thrush, scratches, and other skin problems
                                                                    </li>
                                                                    <li>
                                                                        general cleanliness of the dog
                                                                    </li>
                                                                    <li>
                                                                        monitoring of the dog's health by checking for cuts, hot spots, swelling, lameness, or changes in temperament, all of which could be indicative of illness
                                                                    </li>
                                                                    <li>
                                                                        forging of a closer bond between dog and owner
                                                                    </li>
                                                                    <li>
                                                                        reducing infestation load of external parasites on skin
                                                                    </li>
                                                                    <li>
                                                                        avoid matting that can potentially cause health concerns like skin irritation or the entrapment of harmful bacteria in the coat[4]
                                                                    </li>
                                                                </ul>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="X-Ray" title="X-Ray">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={XRay} alt="X-Ray" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>X-Ray </h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>Radiographs (X-rays) are usually the first test administered to evaluate your pet. Our office utilizes digital X-rays because they are more accurate and display a higher contrast. Because of their accuracy, technicians are able to take fewer images resulting in less pet discomfort. Digital X-rays also project less radiation than traditional X-rays, which allows your pet to avoid unnecessary exposure.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Pathology" title="Pathology">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={pathology} alt="Pathology" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Pathology</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>Clinical pathology is concerned with the diagnosis of disease based on the laboratory analysis of bodily fluids such as blood, urine or cavitary effusions, or tissue aspirates using the tools of chemistry, microbiology, hematology and molecular pathology. The Indian, European, Japanese and American Colleges of Veterinary Pathologists certify veterinary clinical pathologists. The American College of Veterinary Pathologists certification exam consists of four parts: General Pathology (shared with the Anatomic Pathology certifying examination), Cytology and Surgical Pathology, Hematology, and Clinical Chemistry. </p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Nail Cutting" title="Nail Cutting">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={NailCutting} alt="Nail Cutting" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Nail Cutting</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>Clip only the tip of the nail, straight across. Include the dewclaws, located on the inner side of the paw. Avoid clipping past the curve of the nail or you risk hitting what is called the quick (the pink area of the nail that contains the blood vessels). A nick there is painful and will bleed.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Hair Cutting" title="Hair Cutting">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={HairCutting} alt="Hair Cutting" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Hair Cutting</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>Dog grooming refers to both the hygienic care and cleaning of a dog, as well as a process by which a dog's physical appearance is enhanced for showing or other types of competition. A dog groomer (or simply "groomer") is a person who earns their living grooming dogs</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Ear Cleaning" title="Ear Cleaning">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={EarCleaning} alt="Ear Cleaning" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Ear Cleaning</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>Cleaning your dog's ears does not require any special equipment. A good quality ear cleaning solution, some cotton balls or gauze, and some treats to reward your dog are all that is needed. Do not use cotton tip applicators (Q-tips®), due to the risk of perforating the ear drum or causing trauma to the ear canal.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Bathing" title="Bathing">
                                <div className='Treatment-Con'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-5'>
                                                <div className='TabPalnel-Images'>
                                                    <Zoom>
                                                        <div className='TP-Image'>
                                                            <img src={bathing} alt="Bathing" />
                                                        </div>
                                                    </Zoom>
                                                </div>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='TabPanel-Information-con'>
                                                    <div className='TabPanel-Information'>
                                                        <div className='TabPanel-Information-title'>
                                                            <Bounce cascade>
                                                                <h2>Bathing</h2>
                                                            </Bounce>
                                                        </div>
                                                        <div className='TabPanel-Information-description'>
                                                            <Fade>
                                                                <p>While many different beliefs surround how often you should bathe your dog, a warm bath is almost always an enjoyable treat for your pet. In addition to keeping your dog clean after playing outside and running around the yard, there are also many benefits of bathing for their health, their happiness, and even your health too! As you plan for your pets bathing needs this year, consider these benefits of regular bathing for your dog.</p>
                                                                <h6>Please contact us for more information.</h6>
                                                            </Fade>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </section>
            {/* /////////////////// */}

            <Footer />

        </>
    )
}

export default Facilities